.attendease-search-field {
  width: 100%;
  position: relative;
  margin-bottom: 15px;

  .form-control {
    font-size: 16px;
    padding: 0 10px 0 40px;
    margin-bottom: 10px;
    border: none;
    background-color: var(--default-light-blue-background) !important;
  }

  .attendease-search-field-container {
    position: relative;

    .attendease-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      color: var(--default-help-text-color);
      font-size: 1.02rem;
      transform: translate(0, -50%);
    }

    .attendease-search-clear {
      left: 5px;
      font-size: 1.2rem;
      padding: 7px 10px 5px;
      color: var(--default-red-dark);
    }
  }
}

.attendease-search-actions {
  display: flex;
  margin-top: -7px;
  justify-content: space-between;
  align-items: center;
}

.attendease-search-result {
  font-size: 14px;
  color: var(--default-text-color);
}

/*
  Hide the ‘X’ from Chrome/Safari.
  The React component already shows an 'X' for all browsers.
*/
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
