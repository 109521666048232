/*
  Default Brand Colors and Font for the dashboard if TS Tickets URL.
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  --ts-primary: #292d43;
  --ts-secondary: #f48034;
  --ts-headings-color: #292d43;
  --ts-default-gray: #5c5f70;
  --ts-default-green: #578e75;
  --ts-default-gray-light: #787c94;
}

.attendease-onsite-checkin-app.tripleseat_tickets {
  /* Text & Links */
  color: var(--ts-default-gray);

  div, span, p {
    font-family: 'Montserrat', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--ts-headings-color);
    font-family: 'Montserrat', sans-serif;
  }

  a {
    color: var(--ts-primary);
  }

  /* Buttons */
  a.btn.btn-success,
  button.btn.btn-success,
  input.btn.btn-success {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    background: var(--ts-default-green);
    border-color: var(--ts-default-green);
    border-width: 2px;
  }

  /* Header */
  .attendease-header {
    background: var(--ts-primary);
  }

  /* Text */
  .attendease-session-time-slot .attendease-time-slot-name,
  .attendease-session-time-slot .attendease-time-slot-name a,
  .attendease-scan-session-qrcode-modal .modal-title {
    color: var(--ts-primary);
  }

  .text-green {
    color: var(--ts-default-green);
  }

  .attendease-nav.nav {
    justify-content: center;
    a {
      margin: 0 1.5rem;

      &.active {
        color: var(--ts-primary);
      }
    }
  }
}
