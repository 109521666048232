@import '../../styles/breakpoints.scss';

.attendease-scan-session-qrcode-modal {
  @media (min-width: $screen-sm) {
    .modal-fullscreen {
      max-width: 500px;
      height: auto;
      margin: 50px auto 0;
    }
  }

  .modal-header {
    align-items: flex-start;
  }

  .modal-title {
    padding-right: 1rem;
    color: var(--brand-blue);
  }

  & + .modal-backdrop {
    z-index: 1900;
  }
}

.attendease-scan-session-qrcode-info {
  font-size: 1rem;
  margin-bottom: 1rem;
}
