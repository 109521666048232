.attendease-paginator {
  margin: 1.6rem 0 2rem;
  display: flex;
  align-items: stretch;
  text-align: center;
  flex-wrap: wrap;

  .btn.btn-outline-primary,
  >div {
    margin: 0;
    border-radius: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    &.disabled {
      opacity: 0.5;
    }
  }

  >div {
    width: 40px;
    border: 1px solid var(--brand-blue);
    padding: 0.45rem 0;
  }
}
