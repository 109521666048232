.attendease-login-page {
  padding: 0 0 1.5rem;

  .attendease-header {
    margin-bottom: 4rem;

    img {
      margin: 0 auto;
    }
  }
}

.attendease-login-container {
  form {
    margin: 0 auto;
    display: block;
    max-width: 300px;
  }

  .row {
    justify-content: center;
  }

  input.form-control {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 3px;
    font-size: 1.6rem;
    height: 46px;
    font-family: 'Courier New', Courier, monospace;

    &::placeholder {
      text-transform: none;
      font-weight: 400;
      letter-spacing: initial;
      font-size: 1.2rem;
      font-family: var(--font-primary);
    }
  }

  .form-group.has-icon {
    .attendease-icon {
      top: 14px;
      font-size: 1.3rem;
    }

    input.form-control {
      padding-left: 3rem;
    }
  }
}
