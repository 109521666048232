.attendease-user-avatar {
  width: 62px;
  height: 62px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  background-color: var(--default-light-blue-background);


  > img, a > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 50%;
  }
}

.attendease-user-initials {
  top: 50%;
  left: 0;
  width: 100%;
  font-size: 21px;
  font-weight: 400;
  position: absolute;
  transform: translate(0, -50%);
  text-transform: uppercase;
  color: var(--default-help-text-color);
}
