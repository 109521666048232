.attendease-user-profile {
  width: 100%;
  margin-bottom: 24px;

  .attendease-user-profile-name {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .attendease-user-profile-email {
    word-wrap: break-word;
    margin-bottom: 0.2rem;
  }

  .attendease-user-profile-pass {
    margin-bottom: 0;
    color: var(--default-gray-dark);
  }

  &.avatar-position-top {
    .attendease-user-avatar {
      margin: 0 auto;
    }

    .attendease-user-profile-info {
      padding-top: 14px;
      text-align: center;
    }

    .attendease-user-profile-name {
      font-size: 1.25rem;
    }

    .attendease-user-profile-pass {
      font-size: 1.06rem;

      .attendease-icon {
        font-size: inherit;
      }
    }

    .attendease-user-initials {
      font-size: 2rem;
    }
  }

  &.avatar-position-left {
    display: flex;
    min-width: 0;

    .attendease-user-avatar {
      flex: none;
    }

    .attendease-user-profile-info {
      width: 100%;
      padding-left: 13px;
      padding-right: 13px;
      min-width: 0;
    }

    .attendease-user-profile-name {
      font-size: 1.06rem;
      margin-bottom: 5px;
    }

    .attendease-user-profile-email {
      font-size: 0.8rem;
    }

    .attendease-user-profile-pass {
      font-size: 0.82rem;

      .attendease-icon {
        font-size: inherit;
      }
    }
  }
}
