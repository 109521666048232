.attendease-social-profiles {
  margin-top: 0.5rem;

  a {
    font-size: 1.375rem;
    display: inline-block;
    color: var(--brand-blue);

    .attendease-icon {
      font-size: inherit;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
