.attendease-timeslot-session-name {
  font-size: 1.1rem;
  margin-bottom: 8px;
  color: var(--brand-blue);

  span {
    color: var(--default-text-color);
  }
}

.attendease-timeslot-location {
  display: flex;
  align-items: flex-start;

  .attendease-icon {
    padding-top: 6px;
  }
}

.attendease-timeslot-info {
  line-height: 1.65;
  font-size: 0.94rem;

  p {
    margin-bottom: 0.3rem;
  }

  .attendease-icon {
    width: 25px;
    font-size: 1.2rem;
  }

  .attendease-panel {
    position: relative;
    padding-bottom: 45px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .attendease-checkin-counter {
    margin-bottom: 1.4rem;
  }

  button.btn.attendease-scan-button {
    display: block;
    width: 100%;
    border-radius: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #4C6788;
    height: 55px;
    line-height: 55px;
    padding: 0;
    font-size: 1.12rem;

    .attendease-icon {
      margin-right: var(--default-vertical-lg-spacing);
    }
  }
}
