.attendease-attendee-details {
  .attendease-user-profile.avatar-position-left {
    .attendease-user-profile-info {
      padding-top: 0.3rem;
    }

    .attendease-user-profile-name {
      font-size: 1.22rem;
    }

    .attendease-user-profile-pass {
      font-size: 0.94rem;
    }
  }

  .attendease-attendee-summary {
    .attendease-checkin-button.checkin-date-time,
    .attendease-checkin-button.btn {
      width: 100% !important;

      .attendease-icon {
        display: inline-block;
        margin: 0 6px 0 0;
        font-size: 1rem;
        vertical-align: middle;
      }
    }

    .attendease-checkin-button.btn {
      border-radius: 6px !important;
      font-size: 0.9rem !important;
      padding: 0.6rem 1.35rem !important;
      max-width: 450px !important;
      margin: 0 !important;

      &.btn-success,
      &.btn-info {
        text-transform: uppercase;
      }

      &.btn-info {
        background: var(--default-blue-gray);
        border-color: var(--default-blue-gray);

        .attendease-icon {
          margin: 0 0 0 10px;
          color: #fff;
        }
      }
    }

    .attendease-checkin-button.checkin-date-time {
      background: none;
      border: none;
      font-weight: 500;
      text-align: left;
      font-size: 0.85rem !important;
      padding: 0 !important;
      color: var(--default-green-dark) !important;
    }
  }

}
