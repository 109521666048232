.attendease-attendee-time-slot {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary-border-color);

  .attendease-time-slot-name {
    font-size: 1.05rem;
    margin-bottom: 5px;
    position: relative;
    padding-right: 35px;

    &, a {
      color: var(--brand-blue);
    }
  }

  p,
  time {
    font-size: 0.95rem;
    display: block;
    margin-bottom: 5px;
    line-height: 1.3;
  }

  .attendease-attendee-time-slot-info {
    padding: var(--default-vertical-lg-spacing) 0;

    time {
      font-size: 0.85rem;
    }
  }

  &:first-child {
    border-top: 1px solid var(--secondary-border-color);
  }
}
