.attendease-back-link {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  display: block;
  font-weight: 500;
  margin-top: -4px;

  .attendease-icon {
    font-size: 1.9rem;
    width: 20px;
  }
}
