@import '../../../styles/breakpoints.scss';

.attendease-page-container {
  @media (max-width: $screen-xs) {
    padding-bottom: 75px;
  }
}

.attendease-container {
  &.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
