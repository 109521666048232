@import '../../../styles/breakpoints.scss';

.attendease-modal {
  @media (max-width: $screen-sm) {
    .modal-dialog {
      max-width: 90%;
      margin: 20px auto 0;
    }
  }

  .modal-header {
    margin-bottom: 0.5rem;
    text-align: center;
    border-radius: 0;

    .modal-title {
      width: 100%;
      color: inherit;
    }
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-footer {
    text-align: center;

    .btn, .btn.active, .btn:hover {
      background: none;
      margin: 0 auto;
      float: none;
      border: none;
      font-weight: 500;
      font-size: 1.1rem;
      height: auto;
      padding: 0 10px;
      color: var(--default-text-color);
    }
  }

  &.modal-default {
    .modal-header {
      background: var(--default-light-blue-background);
      color: var(--default-text-color);
    }
  }

  &.modal-info {
    .modal-header {
      background: var(--default-blue-light);
      color: #fff;
    }
  }

  &.modal-success {
    .modal-header {
      background: var(--default-green);
      color: #fff;
    }
  }

  &.modal-warning {
    .modal-header {
      background: var(--default-orange);
      color: #fff;
    }
  }

  &.modal-error {
    .modal-header {
      background: var(--default-red-dark);
      color: #fff;
    }
  }
}
