/// Buttons
/// @group Form Elements
@mixin attendease-button ($button-color: var(--default-green), $button-text-color: #fff, $hover-color: var(--default-green-dark), $hover-text-color: #fff, $border-color: var(--default-green)) {
  font-family: var(--font-primary);
  border-color: $border-color;
  background-color: $button-color;
  color: $button-text-color;
  font-weight: 600;
  position: relative;
  max-width: 100%;
  border-radius: 6px;
  font-size: 0.9rem;
  padding: 0.45rem 1.35rem;
  text-transform: uppercase;

  &:hover,
  &.active {
    border-color: $hover-color;
    background-color: $hover-color;
    color: $hover-text-color;
    box-shadow: none;
  }
}
