@import '../../../styles/breakpoints.scss';

.attendease-full-size-image {
  position: absolute;
  bottom: -9px;
  right: -2px;

  >.attendease-icon {
    line-height: 0.7;
  }
}

.attendease-full-size-image-window {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  padding: 3rem 1.2rem;
  background: rgba(0, 0, 0, 0.9);
  z-index: 200;

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: ($screen-xs + 1)) {
    top: 50%;
    left: 50%;
    height: auto;
    max-width: 600px;
    transform: translate(-50%, -50%);
  }
}

.attendease-full-size-image-open {
  position: relative;
  font-size: 0.9rem;
  color: #fff;
  padding: 8px 7px 5px;
  background: #677486;
  border-radius: 4px;
}

.attendease-full-size-image-close {
  position: absolute;
  top: 10px;
  right: 1.2rem;
  z-index: 100;
  color: #fff;

  &.attendease-icon {
    font-size: 2rem;
    line-height: 1;
  }
}
