@import '../../styles/breakpoints.scss';

.attendease-header {
  font-size: 0.8rem;
  color: #fff;
  margin-bottom: 0.8rem;
  background: var(--brand-blue);

  p {
    margin: 0;
  }

  img {
    max-width: 155px;
  }

  .navbar {
    flex-wrap: nowrap;
    display: block;

    .container {
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
    }

    @media (min-width: $screen-sm) {
      font-size: 1.1rem;
    }
  }

  .btn.attendease-header-logout {
    &, &:hover, &:focus {
      font-weight: 500;
      color: #fff;
      font-size: 1rem;
      text-transform: uppercase;

      @media (min-width: $screen-sm) {
        font-size: 1.1rem;
      }
    }
  }

  &.tripleseat_tickets {
    img {
      width: 145px;
    }
  }
}

.attendease-event-name {
  flex: none;
  width: 100%;
  display: block;
  font-weight: 500;
  font-size: 0.97rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  border-top: 1px solid #2E4E8B;
  padding: 0.6rem var(--default-horizontal-spacing);

  @media (min-width: $screen-sm) {
    font-size: 1.25rem;

    span {
      max-width: 720px;
      display: block;
      margin: 0 auto;
      padding: 0 1rem;
    }
  }

  @media (min-width: $screen-md) {
    span {
      max-width: 1140px;
    }
  }
}
