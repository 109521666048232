@import '../../styles/breakpoints.scss';

.attendease-nav.nav {
  padding: var(--default-vertical-lg-spacing) 1rem 0.7rem;
  flex-wrap: nowrap;
  margin: 0 auto;
  background: #fff;
  justify-content: center;
  align-items: flex-end;
  text-align: center;

  a {
    display: inline-block;
    font-size: 0.92rem;
    color: var(--default-help-text-color);

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    &.active {
      font-weight: 500;
      color: var(--brand-blue);
    }
  }

  .attendease-icon {
    display: none;
    width: 25px;
    margin: 0 auto 0.4rem;
    font-size: 1.2rem;
    line-height: 1.1;
  }

  @media (min-width: $screen-sm) {
    max-width: 720px;

    a {
      font-size: 1rem;
    }
  }


  @media (min-width: $screen-md) {
    max-width: 1140px;
    margin: 0 1rem;
  }

  @media (min-width: ($screen-xs + 1)) {
    margin-bottom: var(--default-vertical-lg-spacing);

    a {
      padding: 0 0.02rem 0.38rem;
      border-bottom: 2px solid #fff;

      &.active {
        font-weight: 500;
        color: var(--brand-blue);
        border-color: var(--brand-blue);
      }
    }
  }

  @media (max-width: $screen-xs) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1900;
    justify-content: space-between;
    box-shadow: 0px -3px 6px 0px rgba(0,0,0,0.08);

    .attendease-icon {
      display: block;
    }
  }
}
