button.btn.attendease-checkin-button {
  margin: 0;
  flex: none;
  white-space: nowrap;
  margin-right: -0.95rem;

  // override Bootstrap
  width: 90px !important;
  padding: 0.3rem 0.2rem !important;
  color: #fff !important;
  border-radius: 0 !important;

  .attendease-icon {
    display: block;
    font-size: 1.2rem;
    margin: 0 auto 0.85rem;
  }

  .attendease-spinner-component {
    font-size: 0.8rem;
  }

  &.btn-success,
  &.btn-secondary {
    text-transform: none;
  }

  &.btn-info {
    font-size: 0.95rem;
    font-weight: 500;
    text-transform: uppercase;
    background: var(--default-blue-light);

    .attendease-icon {
      margin-top: 0.6rem;
      font-size: 1.5rem;
    }
  }

  &.btn-secondary {
    font-size: 0.75rem;
    line-height: 1.2;
    white-space: initial;

    .attendease-icon {
      margin-bottom: 0.4rem;
    }
  }
}
