.attendease-icon {
  vertical-align: middle;
  line-height: 1;

  &.before-text {
    margin-right: 8px;
  }

  &.after-text {
    margin-left: 8px;
  }

  &.has-action {
    cursor: pointer;
  }

  &.fa-ticket-alt {
    transform: rotate(-30deg);
  }

  // there is no category/type set for FontAwesome brands
  // new instagram icon looks really thin if using it as white
  &.fa-instagram {
    font-weight: 600;
  }
}
