:root {
  // Attendease brand colors
  --brand-yellow: #FFCB58;
  --brand-blue: #223265;

  // Attendease secondary colors
  --default-blue-light: #6B7188;
  --default-blue-gray: #4C6788;
  --default-red: #F15C44;
  --default-red-dark: #F15C44;
  --default-green: #74a97f;
  --default-green-dark: #03AC60;
  --default-orange: #e3680e;

  // Default text colors
  --default-text-color: #333;
  --default-help-text-color: #585858;
  --default-border-color: #D3D3D3;
  --secondary-border-color: #EAEAEA;

  // Default background colors
  --primary-gray-background: #FAFAFA;
  --secondary-gray-background: #F0F0F0;
  --default-light-blue-background: #F1F2F8;
  --default-shadow-color: #DAD6E2;

  // Helpers - shades of gray
  --default-gray: #838383;
  --default-gray-light: #ccc;
  --default-gray-dark: #444;
  --default-gray-darker: #222;
}

// Helper classes
.text-red {
  color: var(--default-red);
}

.text-red-dark {
  color: var(--default-red-dark);
}

.text-orange {
  color: var(--default-orange);
}

.text-green {
  color: var(--default-green);
}

.text-blue-light {
  color: var(--default-blue-light);
}

.text-blue-dark {
  color: var(--brand-blue);
}
