.attendease-scan-confirmation-modal {
  .btn {
    margin: 0.3rem 0.5rem;
  }

  .attendease-user-profile {
    margin-bottom: 0.9rem;
  }

  .attendease-scan-notes-total,
  .attendease-scan-registered-by {
    font-size: 0.95rem;
  }
}
