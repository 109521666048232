@import './breakpoints.scss';
@import './colors.scss';
@import './mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');

// Global variables
:root {
  --font-primary: 'Work Sans';
  --default-vertical-spacing: 0.55rem;
  --default-vertical-lg-spacing: 0.85rem;
  --default-horizontal-spacing: 1.02rem;
}

// General Font styles
html {
  font-size: 100%;
  height: -webkit-fill-available;
}

body {
  font-size: 0.92rem;
  font-weight: 400;
  line-height: 1.2;
  background-color: #F6F5FA;
  color: var(--default-text-color);
  font-family: var(--font-primary), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-family: var(--font-primary), sans-serif;
  color: var(--default-text-color);

  > small {
    font-size: .75em;
    display: block;
    padding-top: 14px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px 0;
}

h1, .h1 {
  font-size: 1.5625rem;

  @media (max-width: $screen-sm) {
    font-size: 1.375rem;
  }
}

h2, .h2 {
  font-size: 1.45rem;

  @media (max-width: $screen-sm) {
    font-size: 1.3rem;
  }
}

h3, .h3 {
  font-size: 1.2rem;

  @media (max-width: $screen-sm) {
    font-size: 1rem;
  }
}

h4, .h4 {
  font-size: 1.2em;
}

h5, .h5 {
  font-size: 1.1em;
}

h6, .h6 {
  font-size: 1em;
}

p, ul, ol {
  margin: 0 0 20px;

  &.help-text {
    color: var(--default-text-color);
  }
}

figure {
  margin: 0;
}

a {
  color: var(--default-gray-dark);
  transition: all ease-in-out 250ms;
  text-decoration: none;

  &:hover {
    color: var(--brand-blue);
    cursor: pointer;
    text-decoration: underline;
  }

  &.nohover {
    &:hover {
      color: var(--brand-blue);
      text-decoration: none;
    }
  }

  &:focus {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--default-gray-dark);
  }

  &.remove {
    color: var(--default-red-dark);
  }

  &.underline {
    text-decoration: underline;
  }

  &.small {
    font-size: 15px;
  }
}

.help-block {
  font-size: 1rem;
  color: var(--default-help-text-color);
}

// General spacing styles
.pt-1 {
  padding-top: 14px;
}

.pt-2 {
  padding-top: 24px;
}

.pb-1 {
  padding-bottom: 14px;
}

.pb-2 {
  padding-bottom: 24px;
}

.pl-1 {
  padding-left: var(--default-horizontal-spacing);
}

.pr-1 {
  padding-right: var(--default-horizontal-spacing);
}

.mb-1 {
  margin-bottom: 14px;
}

.mb-2 {
  margin-bottom: 24px;
}

// Custom font styles
.fw-medium {
  font-weight: 500;
}

.fa-lg {
  font-size: 0.92rem;
}

// General container styles
.attendease-onsite-checkin-app {
  width: 100%;
  margin: 0 auto;
  max-width: $screen-md;
}

.attendease-container {
  padding-top: var(--default-vertical-spacing);
  padding-bottom: var(--default-vertical-spacing);
}

/// @group Bootstrap Overrides: Container
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .hz-padding {
  padding-left: var(--default-horizontal-spacing);
  padding-right: var(--default-horizontal-spacing);
}

/// @group Bootstrap Overrides: Buttons
a.btn,
button.btn,
input.btn {
  outline: none;
  transition: all ease-in-out 250ms;

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }

  &.before-text {
    margin-right: 5px;
  }

  &.after-text {
    margin-left: 5px;
  }

  + .btn {
    margin-left: 14px;
  }

  &.pull-right {
    margin-left: 14px;
  }

  // default buttons
  &.btn-primary {
    @include attendease-button(var(--default-blue-gray), #fff, var(--default-blue-gray), #fff,  var(--default-blue-gray));
  }

  &.btn-success {
    @include attendease-button(var(--default-green), #fff, var(--default-green-dark), #fff, var(--default-green));
  }

  &.btn-danger {
    @include attendease-button(var(--default-red), #fff, var(--default-red), #fff, var(--default-red));
  }

  // outline buttons
  &.btn-outline-primary {
    @include attendease-button(#fff, var(--brand-blue), var(--brand-blue), #fff, var(--brand-blue));
  }

  &.btn-sm {
    padding: 0.3rem 0.75rem;
    font-size: 0.82rem;
  }

  &.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }

  &, &:focus, &:hover {
    &.reset-styles {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      height: auto;
      font-weight: 400;
      text-transform: initial;
      border-radius: 0;
      color: var(--default-text-color);
    }
  }
}

/// @group Bootstrap Overrides: FormGroup
.form-group {
  position: relative;

  &.has-icon {
    .attendease-icon {
      position: absolute;
      left: 15px;
      top: 12px;
      font-size: 17px;
      line-height: 1;
      color: var(--default-help-text-color);
    }

    input.form-control {
      padding-left: 40px;
    }
  }
}

/// @group Bootstrap Overrides: FormControl
input {
  &.form-control {
    height: 40px;
    padding: 0 15px;
    font-size: 0.9rem;
    transition: all ease-in-out 250ms;
    box-shadow: none;
    max-width: 1300px;
    border-radius: 5px;
    background-color: #fff;
    color: var(--default-text-color);
    border: 1px solid var(--default-border-color);

    &:focus, &:hover {
      box-shadow: none;
      color: var(--brand-blue);
      border-color: var(--brand-blue);

      &:disabled {
        box-shadow: none;
        border-color: var(--default-border-color);
      }
    }

    &.disabled {
      opacity: 0.5;
    }

    &.has-error {
      border-color: var(--default-red-dark);
    }
  }
}

/// @group Bootstrap Overrides: Tabs
.attendease-tabs.nav-tabs {
  border: none;
  margin-bottom: 0.5rem;
  flex-wrap: nowrap;

  .nav-item {
    white-space: nowrap;

    .nav-link {
      font-size: 0.92rem;
      padding: 0 0.02rem 0.30rem;
      border: none;
      border-bottom: 2px solid #fff;
      text-transform: uppercase;
      color: var(--default-text-color);

      &.active {
        font-weight: 500;
        color: var(--brand-blue);
        border-color: var(--brand-blue);
      }
    }

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

.tab-content {
  padding: var(--default-vertical-spacing) 0;
}

// Qr Code Reader module
.attendease-qr-reader {
  margin: 0 auto;

  @media (min-width: $screen-sm) {
    max-width: 550px;
  }
}

.modal.attendease-loading-modal {
  z-index: 2000;

  .modal-body {
    padding: 2.5rem 1rem;
  }
}

// Attendease default panel styles to encapsulate content
.attendease-panel {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 1.1rem;
  padding: 1rem 0.95rem;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
}

// Attendease Select
.attendease-select {
  margin-bottom: var(--default-vertical-lg-spacing);
}

// Loading section
.checkin-app-loading {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@import './eventup_planner_colors.scss';
@import './ts_colors.scss';
