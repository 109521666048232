.attendease-attendee-listing {
  .attendease-attendee-info {
    border-bottom: 1px solid var(--secondary-border-color);

    &:first-child {
      border-top: 1px solid var(--secondary-border-color);
    }
  }

  .attendease-user-avatar,
  .attendease-user-profile-info {
    margin-top: 0.95rem;
  }

  .attendease-user-profile-pass {
    .attendease-icon {
      display: none;
    }
  }
}
