.attendease-session-time-slot {
  padding-bottom: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--default-border-color);
  }

  .attendease-time-slot-name {
    font-size: 1.05rem;
    margin-bottom: 5px;
    position: relative;
    padding-right: 35px;

    &, a {
      color: var(--brand-blue);
    }
  }

  p,
  time {
    font-size: 0.95rem;
    display: block;
    margin-bottom: 5px;
    line-height: 1.3;
  }

  .btn.attendease-time-slot-scan-button {
    max-width: 320px;
    margin-top: 5px;

    .attendease-icon {
      margin-right: var(--default-vertical-lg-spacing);
    }
  }
}

.attendease-session-details-button {
  top: -16px;
  right: 0;
  font-size: 2rem;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  color: var(--default-gray);

  .attendease-icon {
    font-size: inherit;
  }
}

.attendease-scan-confirmation-modal {
  &.modal {
    z-index: 2000;
  }

  .modal-body {
    .btn {
      min-width: 200px;
      margin-left: 0;
    }
  }
}
