.attendease-collapse-info {
  button.btn.attendease-collapse-button {
    margin-bottom: 6px;

    .attendease-icon:last-child {
      font-size: 1.4rem;
    }

    &, &:hover, &:focus {
      display: block;
      width: 100%;
      position: relative;
      text-align: left;
      font-size: 1rem;
      padding: var(--default-vertical-spacing) var(--default-horizontal-spacing);
      background: var(--default-light-blue-background);

      .attendease-icon {
        margin-right: 10px;
      }
    }
  }

  &.active {
    button.btn.attendease-collapse-button {
      font-weight: 500;
    }
  }
}

.attendease-collapse-info-status {
  font-size: 1.5rem;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);

  .attendease-icon {
    font-size: inherit;
  }

  &.fa-angle-up {
    color: #000;
  }

  &.fa-angle-down {
    color: var(--default-help-text-color);
  }
}

.attendease-collapse-content {
  padding: var(--default-vertical-spacing) var(--default-horizontal-spacing);
}
