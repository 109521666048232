/*
  Default Brand Colors and Font for the dashboard if Eventup Planner URL.
*/

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --color-primary: #223265;
  --color-secondary: #74a97f;
  --color-headings-color: #223265;
  --color-default-gray: #585858;
  --color-default-green: #74a97f;
  --color-default-gray-light: #787c94;
}

.attendease-onsite-checkin-app,
.attendease-onsite-checkin-app.eventup_planner,
.attendease-onsite-checkin-app.attendease {
  /* Text & Links */
  color: var(--color-default-gray);

  div, span, p {
    font-family: 'Nunito', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito', sans-serif;
    color: var(--color-headings-color);
  }

  a {
    color: var(--color-primary);
  }

  /* Buttons */
  a.btn.btn-success,
  button.btn.btn-success,
  input.btn.btn-success {
    color: #fff;
    font-family: 'Nunito', sans-serif;
    background: var(--color-default-green);
    border-color: var(--color-default-green);
    border-width: 2px;
  }

  /* Header */
  .attendease-header {
    background: var(--color-primary);
  }

  /* Text */
  .attendease-session-time-slot .attendease-time-slot-name,
  .attendease-session-time-slot .attendease-time-slot-name a,
  .attendease-scan-session-qrcode-modal .modal-title {
    color: var(--color-primary);
  }

  .text-green {
    color: var(--default-green-dark) !important;
  }

  .attendease-nav.nav {
    justify-content: center;
    a {
      margin: 0 1.5rem;

      &.active {
        color: var(--color-primary);
      }
    }
  }
}
